const accordionDeliveryContent = [
  {
    title: 'Оплата',
    text: 'Оплата производится наличными или банковской картой при получении заказа',
    id: 'aboutPayment',
  },
  {
    title: 'Доставка',
    text: 'Доставка производится из Москвы транспортными компаниями: CDEK, Деловые Линии, Пэк и Почтой России. В зависимости от удалённости от Москвы стандартный срок составляет 2–8 дней, если вам необходима срочная доставка — оставьте, пожалуйста, комментарий к заказу при оформлении',
    id: 'aboutDeliveryAndReturns',
  },
  {
    title: 'Возврат',
    text: 'Вернуть купленные товары можно сделав заявку по телефону 8 800 900-90-90',
    id: 'aboutDeliveryAndReturns',
  },
];

export default accordionDeliveryContent;
