import Icon from '../Icon/Icon';
import vk from '../../images/brand-vk.svg';

export default function VKIcon() {
  return (
    <Icon
      image={vk}
      alt="Написать во ВКонтакте"
      link="https://vk.com/ecome"
    ></Icon>
  );
}
